import React, { useState, useEffect } from 'react';
import { HiChevronLeft, HiChevronRight, HiChevronUp } from 'react-icons/hi'; // Import Chevron icons

const SearchAndCategory = ({ setSearchText, categories, selectedCategory, setSelectedCategory, isDarkMode }) => {
  const [showGoToTop, setShowGoToTop] = useState(false);

  // Show or hide the Go to Top button based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      setShowGoToTop(window.scrollY > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll to top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Scroll function for categories
  const scrollContainer = (direction) => {
    const container = document.getElementById('categories-scroll-container');
    const scrollAmount = 150; // Pixels to scroll
    container.scrollLeft += direction === 'left' ? -scrollAmount : scrollAmount;
  };

  return (
    <div>
      {/* Search and Categories Section */}
      <div className={`sticky top-0 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'} z-10`}>
        <div
          className={`container mx-auto flex flex-col items-center px-4 py-4 border rounded-lg ${
            isDarkMode ? 'border-gray-700' : 'border-gray-300'
          }`}
        >
          {/* Search Bar */}
          <input
            type="text"
            placeholder="Type or Paste your Text Here..."
            className={`w-full md:w-1/2 p-2 border rounded-lg mb-4 ${
              isDarkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-900 border-gray-300'
            }`}
            onChange={(e) => setSearchText(e.target.value)}
          />

          {/* Horizontal Scrollable Categories */}
          <div className="relative w-full flex items-center">
            {/* Scroll Left Button */}
            <button
              onClick={() => scrollContainer('left')}
              className="p-2 text-gray-500 hover:text-gray-800 dark:hover:text-gray-200 transition-transform transform hover:scale-110"
              aria-label="Scroll Left"
            >
              <HiChevronLeft size={24} />
            </button>

            {/* Scrollable Categories */}
            <div
              id="categories-scroll-container"
              className="flex-1 overflow-x-auto whitespace-nowrap scrollbar-hide flex space-x-2"
            >
              {/* All Categories Button */}
              <button
                className={`px-4 py-2 rounded ${
                  selectedCategory === 'All Categories'
                    ? 'bg-purple-600 text-white'
                    : isDarkMode
                    ? 'bg-gray-700 text-white'
                    : 'bg-white text-purple-600 border border-purple-600 hover:bg-purple-600 hover:text-white'
                }`}
                onClick={() => setSelectedCategory('All Categories')}
              >
                All Categories
              </button>
              {/* Dynamic Categories */}
              {categories.map((category) => (
                <button
                  key={category}
                  className={`px-4 py-2 rounded ${
                    selectedCategory === category
                      ? 'bg-purple-600 text-white'
                      : isDarkMode
                      ? 'bg-gray-700 text-white'
                      : 'bg-white text-purple-600 border border-purple-600 hover:bg-purple-600 hover:text-white'
                  }`}
                  onClick={() => setSelectedCategory(category)}
                >
                  {category}
                </button>
              ))}
            </div>

            {/* Scroll Right Button */}
            <button
              onClick={() => scrollContainer('right')}
              className="p-2 text-gray-500 hover:text-gray-800 dark:hover:text-gray-200 transition-transform transform hover:scale-110"
              aria-label="Scroll Right"
            >
              <HiChevronRight size={24} />
            </button>
          </div>
        </div>
      </div>

      {/* Go to Top Button */}
      {showGoToTop && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-6 right-6 p-3 rounded-full bg-purple-600 text-white hover:bg-purple-700 shadow-lg transition-transform transform hover:scale-110"
          aria-label="Go to Top"
        >
          <HiChevronUp size={24} />
        </button>
      )}
    </div>
  );
};

export default SearchAndCategory;
